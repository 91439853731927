







































import {
  Component, Vue,
} from 'vue-property-decorator';
import ContractingStatusModerateSignYourContractViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/contracting-status-moderate/contracting-status-moderate-sign-your-contract-view-model';

@Component({ name: 'ContractingStatusModerateSignYourContract' })
export default class ContractingStatusModerateSignYourContract extends Vue {
  contracting_status_moderate_sign_contract_model = Vue.observable(
    new ContractingStatusModerateSignYourContractViewModel(this),
  );

  mounted() {
    this.contracting_status_moderate_sign_contract_model.initialize();
  }
}

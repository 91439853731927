import TYPES from '@/types';
import Vue from 'vue';

// Application
import { GetCustomerPersonalInformationQuery }
  from '@/modules/customer/personal-information/application/queries';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class ContractingStatusModerateSignYourContractViewModel {
  @Inject(TYPES.GET_CUSTOMER_PERSONAL_INFORMATION_QUERY)
  private readonly get_customer_personal_information_query!: GetCustomerPersonalInformationQuery;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.contracting-status-moderate.contracting-status-moderate-sign-your-contract';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  email = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  initialize = async () => {
    await this.getUsersEmail();
  }

  getUsersEmail = async () => {
    try {
      const { email } = await this.get_customer_personal_information_query.execute();
      this.email = email;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_users_email'));
    }
  }

  closeModal = () => {
    this.view.$emit('closeModal');
  }
}
